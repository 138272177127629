@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #0056b3;
  --secondary-color: #17a2b8;
  --accent-color: #ffc107;
  --dark-color: #343a40;
  --light-color: #f8f9fa;
  --text-color: #333;
}

body {
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  background-image: url('https://www.toptal.com/designers/subtlepatterns/patterns/papyrus.png');
  background-repeat: repeat;
}

.navbar {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0,0,0,.1);

  .navbar-brand, .nav-link {
    color: var(--primary-color) !important;
    font-weight: 600;
  }

  .nav-link {
    &:hover, &.active {
      color: var(--secondary-color) !important;
    }
  }
}

.hero {
  background-size: cover;
  background-position: center;
  color: white;
  padding: 150px 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .container {
    position: relative;
    z-index: 1;
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
}

.btn-primary {
  background-color: var(--accent-color);
  border-color: var(--accent-color);
  color: var(--dark-color);
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: darken(#ffc107, 10%);
    border-color: darken(#ffc107, 10%);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,.1);
  }
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 3rem;
  text-align: center;
}

.card {
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0,0,0,.1);
  }
}

.service-icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 1rem;
}

footer {
  background-color: var(--dark-color);
  color: var(--light-color);
  padding: 3rem 0;

  .social-icons a {
    color: var(--light-color);
    font-size: 1.5rem;
    margin-right: 1rem;
  }
}

@media (max-width: 768px) {
  .hero {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .section-title {
    font-size: 2rem;
  }
}

.hero {
  background-size: cover;
  background-position: center;
  color: white;
  padding: 150px 0;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .container {
    position: relative;
    z-index: 1;
  }
  
  h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
}

.hover-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  }
}

.core-capabilities {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  
  .capability-card {
    background: white;
    border-radius: 8px;
    padding: 2rem;
    height: 100%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    }
    
    .capability-icon {
      color: var(--primary-color);
      margin-bottom: 1rem;
    }
    
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: var(--primary-color);
    }
    
    p {
      color: var(--text-color);
    }
  }
}

.btn-outline-primary {
  border-width: 2px;
  font-weight: 600;
  
  &:hover {
    background-color: var(--primary-color);
    color: white;
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 100px 0;
    
    h1 {
      font-size: 2.5rem;
    }
    
    p {
      font-size: 1rem;
    }
  }
}

.contact-section {
  background-color: #f8f9fa;

  .contact-info-card,
  .contact-form-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    height: 100%;
  }

  .contact-info-card {
    .google-map {
      margin-top: 1rem;
      border-radius: 10px;
      overflow: hidden;
    }
  }

  .form-control {
    border: none;
    border-bottom: 2px solid #e9ecef;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;

    &:focus {
      box-shadow: none;
      border-color: var(--primary-color);
    }
  }

  .btn-primary {
    border-radius: 50px;
    padding: 0.75rem 2rem;
  }
}


.about-page {
  .hero-section {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    padding: 100px 0;
    
    h1 {
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }

  .mission-section {
    img {
      transition: transform 0.3s ease-in-out;
      
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .values-section {
    .value-icon {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
    
    .card {
      transition: transform 0.3s ease-in-out;
      
      &:hover {
        transform: translateY(-10px);
      }
    }
  }

  .timeline-section {
    .timeline {
      position: relative;
      padding: 0;
      list-style: none;
      
      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 2px;
        margin-left: -1px;
        background-color: var(--primary-color);
      }
    }
    
    .timeline-item {
      position: relative;
      margin-bottom: 50px;
      
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
    
    .timeline-badge {
      color: #fff;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 1.4em;
      text-align: center;
      position: absolute;
      top: 16px;
      left: 50%;
      margin-left: -25px;
      background-color: var(--primary-color);
      z-index: 100;
      border-radius: 50%;
    }
    
    .timeline-content {
      position: relative;
      width: 45%;
      padding: 20px;
      background-color: #fff;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      
      &:before {
        content: "";
        position: absolute;
        top: 26px;
        right: -15px;
        border-top: 15px solid transparent;
        border-left: 15px solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 15px solid transparent;
      }
    }
    
    .timeline-item:nth-child(even) .timeline-content {
      float: right;
      
      &:before {
        left: -15px;
        right: auto;
        border-left: 0;
        border-right: 15px solid #fff;
      }
    }
  }

  .team-section {
    .card {
      overflow: hidden;
      
      img {
        transition: transform 0.3s ease-in-out;
      }
      
      &:hover img {
        transform: scale(1.1);
      }
    }
  }
}

@media (max-width: 768px) {
  .about-page {
    .timeline-section {
      .timeline:before {
        left: 40px;
      }
      
      .timeline-badge {
        left: 15px;
        margin-left: 0;
        top: 16px;
      }
      
      .timeline-content {
        width: calc(100% - 90px);
        float: right;
        
        &:before {
          left: -15px;
          right: auto;
          border-left: 0;
          border-right: 15px solid #fff;
        }
      }
    }
  }
}